import React from "react"
import { Link } from "gatsby"

import Layout from "src/components/layout"
import SEO from "src/components/seo"
import PageTitle from "src/components/page-title"

import gumtreeImage from "src/images/case-study-images/luke-todd@2x.jpg"
import gumtreeHelpCenter from "src/images/case-study-images/gumtree-helpcenter.jpg"

function Gumtree() {
  return (
    <Layout>
      <SEO title="Gumtree: Nickelled Case Studies" />
      <div class="bg-white overflow-hidden">
        <div class="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div class="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen"></div>
          <div class="mx-auto text-base max-w-prose lg:max-w-none">
            <h2 class="text-base text-oOrange font-semibold tracking-wide uppercase">
              Case Study
            </h2>
            <h1 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Case Study: Gumtree
            </h1>
          </div>
          <div class="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div class="relative lg:row-start-1 lg:col-start-2">
              <svg
                class="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
                width="404"
                height="384"
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      class="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="384"
                  fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
                />
              </svg>
              <div class="relative text-base mx-auto max-w-prose lg:max-w-none">
                <figure>
                  <div class="aspect-w-12 aspect-h-7 lg:aspect-none">
                    <img
                      class="rounded-lg shadow-lg object-cover object-center"
                      src={gumtreeImage}
                      alt="Luke Todd"
                      width="1184"
                      height="1376"
                    />
                  </div>
                </figure>
              </div>
            </div>
            <div class="mt-8 lg:mt-0">
              <div class="text-base max-w-prose mx-auto lg:max-w-none">
                <p class="text-lg text-gray-500">
                  If you’re in the UK, odds are you’ve heard of Gumtree. Gumtree
                  is the UK’s largest website for local community classifieds.
                  With around 8 million monthly unique visitors and nearly
                  one-fifth of the UK on Gumtree, it’s one of the most visited
                  sites in the UK. That makes it the go-to place for anything
                  from selling a couch, finding a new roommate or adopting a
                  puppy.
                </p>
                <p class="text-lg text-gray-500">
                  But supporting such a diverse user base with a broad range of
                  web savvy, skills and scenarios was a demanding task, and
                  Gumtree’s support team was struggling to keep up.
                </p>
              </div>
              <div class="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                <h3>The Problem? Too Many Repetitive Support Requests</h3>
                <p>
                  Gumtree doesn’t have a consistent stream of regular visitors,
                  like say, a news site or a social media platform. When
                  existing users log back in, or when new users create an
                  account, they come with a transactional intent: either to buy
                  or sell.
                </p>
                <p>
                  Whether the user creates a new listing or purchase a service,
                  these transactions are often ‘one-and-done’ deals. They won’t
                  be back until the next transaction, which for some users, is
                  years later.
                </p>
                <p>
                  That means they’re also not too eager out to reach out to
                  customer support to get help navigating the platform. After
                  all, they don’t intend to be here for long.
                </p>
                <p>
                  At the time, the constant flow of new and infrequent users was
                  a problem for Gumtree’s support team too. They found
                  themselves spending lots of time answering the same questions
                  and explaining the same processes over and over again.
                </p>
                <p>
                  Though Gumtree was getting tens of thousands of support
                  requests a month, many of the requests were basic and largely
                  predictable:
                </p>
                <ul>
                  <li>How do I post an ad?</li>
                  <li>How do I restore an ad?</li>
                  <li>How do I stop email alerts?</li>
                </ul>
                <p>
                  What’s worse? Many of their least web savvy users were driving
                  up their phone support costs with these mundane help requests
                  - which was also Gumtree’s most expensive support channel.
                </p>
                <p>
                  Gumtree’s support team hypothesized that if their very
                  prominent FAQ page was still leading to phone support calls,
                  it was their FAQ page that needed attention.
                </p>
                <p>
                  So Gumtree’s Head of Customer Operations Luke Todd issued the
                  following challenge:
                </p>
                <p>
                  <strong>
                    Could Gumtree redirect expensive phone support traffic to
                    live chat and email just by improving their FAQ page?
                  </strong>
                </p>
                <p>Here’s what they did.</p>
                <h3>FAQs + Nickelled Guides = Lower Phone Support Costs</h3>
                <p>
                  Gumtree created step-by-step guides for their most frequently
                  asked questions and distributed them across three channels:
                </p>
                <ul class="list-disc list-inside">
                  <li>
                    <strong>The nav bar:</strong> They embedded their most
                    common support requests in a widget across all the support
                    pages
                  </li>
                  <li>
                    <strong>The help center:</strong> They added Nickelled
                    guides to their help center, alongside their FAQs
                  </li>
                  <li>
                    <strong>Live chat and email interactions:</strong> They
                    added links to guides to templated response, so support
                    agents could pull up and send the relevant link across a
                    live chat or email.
                  </li>
                </ul>
                <p>Here’s what their help center looks like:</p>
                <img src={gumtreeHelpCenter} />
                <p>
                  What was the effect of offering up Nickelled guides across the
                  user journey combined with the support team nudging customers
                  with links back to the FAQ section?
                </p>
                <p>
                  <strong>
                    Customers were able to solve their questions online instead
                    of calling in for personal support - and it turns out they
                    liked it that way too
                  </strong>
                  . With an 88% customer satisfaction score, customers who were
                  going through guides were rating their support experience
                  around 10% higher compared to other support channels.
                </p>
                <p>
                  In other words, Gumtree could replace much of their expensive
                  1-to-1 phone support with NIckelled’s clear, visual tutorials
                  and still come out on top.
                </p>
                <p>
                  That made a significant difference to Gumtree’s own bottom
                  line: they saw a 470% ROI after adding Nickelled guides to
                  their customer support arsenal.
                </p>
                <p>
                  Today, Nickelled guides handle nearly one-third of their
                  support requests, which frees up the support team’s time to
                  improve their overall user experience.
                </p>
                <p>
                  “Now when a customer really needs our personal support, we
                  have the time and the bandwidth to help them,” says Todd.
                </p>
                <h3>Gumtree chose Nickelled to:</h3>
                <ul class="list-disc list-inside">
                  <li>
                    Empower their customer support team to send help fast across
                    email, live chat and social media
                  </li>
                  <li>Expand their self-service/self-help center</li>
                  <li>
                    Eliminate the need to provide live support for frequently
                    asked questions
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Gumtree
